import "./App.css"

export default function App() {
	return (
		<div className="background">
			<img alt="LIMIT logo" src={process.env.PUBLIC_URL + "/logo.svg"} />
			<h1>We will be back soon!</h1>
		</div>
	)
}
